import React from 'react';
import { Col } from 'react-bootstrap';

export const ExperienceCard = ({ title, description, imgUrl }) => {
  return (
    <Col md={4} className="experience-card">
      <div className="experience-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="experience-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  )
}
