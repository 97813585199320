import amplify from "../assets/img/amplify.png";
import bootstrap from "../assets/img/bootstrap.jpg";
import c from "../assets/img/C.png";
import cPLUSPLUS from "../assets/img/C++.jpg";
import cSHARP from "../assets/img/C#.png";
import docker from "../assets/img/docker.png";
import flask from "../assets/img/flask.jpg";
import gitlab from "../assets/img/gitlab.png";
import HTMLCSS from "../assets/img/HTMLCSS.png";
import java from "../assets/img/java.png";
import javascript from "../assets/img/javascript.jpg";
import mysql from "../assets/img/mysql.png";
import net from "../assets/img/net.jpg";
import nodejs from "../assets/img/nodejs.png";
import npm from "../assets/img/npm.png";
import postman from "../assets/img/postman.png";
import python from "../assets/img/python.png";
import react from "../assets/img/react.png";
import sql from "../assets/img/sql.png";
import sqla from "../assets/img/sqla.png";
import vscode from "../assets/img/vscode.png";
import yarn from "../assets/img/yarn.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills & Technologies</h2>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={java} alt="java logo" />
                                <h5>Java</h5>
                            </div>
                            <div className="item">
                                <img src={python} alt="python logo" />
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={javascript} alt="javascript logo" />
                                <h5>JavaScript</h5>
                            </div>
                            <div className="item">
                                <img src={c} alt="c logo" />
                                <h5>C</h5>
                            </div>
                            <div className="item">
                                <img src={cPLUSPLUS} alt="c++ logo" />
                                <h5>C++</h5>
                            </div>
                            <div className="item">
                                <img src={cSHARP} alt="c# logo" />
                                <h5>C#</h5>
                            </div>
                            <div className="item">
                                <img src={HTMLCSS} alt="html/css logo" />
                                <h5>HTML/CSS</h5>
                            </div>
                            <div className="item">
                                <img src={sql} alt="sql logo" />
                                <h5>SQL</h5>
                            </div>
                        </Carousel>
                        <div>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                          <div className="item">
                                <img src={react} alt="react logo" />
                                <h5>React</h5>
                          </div>
                          <div className="item">
                                <img src={gitlab} alt="gitlab logo" />
                                <h5>Git/Gitlab</h5>
                          </div>
                          <div className="item">
                                <img src={docker} alt="docker logo" />
                                <h5>Docker</h5>
                          </div>
                          <div className="item">
                                <img src={nodejs} alt="node.js logo" />
                                <h5>Node.js</h5>
                          </div>
                          <div className="item">
                                <img src={net} alt=".net logo" />
                                <h5>.NET</h5>
                          </div>
                          <div className="item">
                                <img src={amplify} alt="aws amplify logo" />
                                <h5>AWS Amplify</h5>
                          </div>
                          <div className="item">
                                <img src={bootstrap} alt="bootstrap logo" />
                                <h5>Bootstrap</h5>
                          </div>
                          <div className="item">
                                <img src={flask} alt="flask logo" />
                                <h5>Flask</h5>
                          </div>
                          <div className="item">
                                <img src={sqla} alt="sqlalchemy logo" />
                                <h5>SQLAlchemy</h5>
                          </div>
                          <div className="item">
                                <img src={mysql} alt="mysql logo" />
                                <h5>mySQL</h5>
                            </div>
                          <div className="item">
                                <img src={vscode} alt="vscode logo" />
                                <h5>VS Code</h5>
                          </div>
                          <div className="item">
                                <img src={postman} alt="postman logo" />
                                <h5>Postman</h5>
                          </div>
                          <div className="item">
                                <img src={npm} alt="npm logo" />
                                <h5>npm</h5>
                          </div>
                          <div className="item">
                                <img src={yarn} alt="yarn logo" />
                                <h5>Yarn</h5>
                          </div>
                        </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <img className="background-image-left" src={colorSharp} alt="background" /> */}
    </section>
  )
}
