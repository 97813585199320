import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import nbaLogo from "../assets/img/nba-mvp-logo.png";
import brighterBeginnings from "../assets/img/brighter-beginnings.png";
import saferTomorrow from "../assets/img/saferTomorrow.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "NBA MVP Predictor",
      description: "Python Application that provides NBA enthusiasts with a tool to forecast the current year's MVP. Web-scraped regular season data from basketball-reference.com for every NBA player. Implemented a ridge regression model to reduce multicollinearity and predict the MVP share for each NBA player",
      imgUrl: nbaLogo,
    },
    {
      title: "Brighter Beginnings",
      description: "Web Application that provides resources and information for low-income K-12 students. The Website has organizations and available scholarships for students, as well as a site-wide search feature with filterable and sortable options. The tech stack is the following: React, Python, SQLAlchemy, and AWS Amplify",
      imgUrl: brighterBeginnings,
    },
    {
      title: "Safer Tomorrow",
      description: "Collaborated with 3 engineers at the TAMU Hackathon to create a website focused on community safety. Integrated a unique safety score system for over 1,000 Texas cities by scraping data from the FBI crimes database, aiding potential homebuyers and residents in making informed living and travel decisions. Implemented a dynamic search bar and Google Maps API integration, enabling users to effortlessly locate and visualize safety scores geographically",
      imgUrl: saferTomorrow,
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Here are some of the projects that I have worked on</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Some Text</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Some Text</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  )
}
