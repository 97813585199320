import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import profile_pic from "../assets/img/profile-pic.jpg"
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
              
              <div>
                <span className="tagline">Hi! I am Ben Yu</span>
                {/* <h1>{`Hi! I'm Ben Yu`} </h1> */}
                  <p>I am a first-generation college student aiming to pursue my passion in coding and computer science. 
                    I am a junior computer science major at the University of Texas, currently looking for software engineering internship opportunities. 
                    I have experience with working in collaborative settings, and am excited to share my technical skills with a team that values innovation and creativity. 
                    I am eager to contribute to real-world projects and learn from experienced professionals in the field. 
                  </p>
              </div>

          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={profile_pic} alt="Header Img"/>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
