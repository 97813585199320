import { Container, Row, Col, Tab } from "react-bootstrap";
import { ExperienceCard } from "./ExperienceCard";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import tfin from '../assets/img/TFIN.jpg'

export const Experience = () => {

  const experiences = [
    {
      title: "Triumph Financial Swe Intern",
      description: "Worked on backend and frontend tasks involving C#, React, and Azure DevOps. Contributed to the development of microservices and handled ticketing, coding, testing, and deployment.",
      imgUrl: tfin,
    },
  ];

  return (
    <section className="experience" id="experience">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Experience</h2>
                <p>Here are some of the experiences I have gained throughout my career</p>
                <Tab.Container id="experience-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          experiences.map((experience, index) => {
                            return (
                              <ExperienceCard
                                key={index}
                                {...experience}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
